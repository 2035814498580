.content.activity{
    display: flex !important;
    height: auto !important;
    width: 100% !important;
    flex-flow: column wrap !important;
    justify-content: center !important;
    margin-top: 70px !important;
    z-index: 1;
    min-height: 70vh;
    padding: 50px 0px;
}

.activity_content{
    width: 90%;
    margin: auto;
    padding: 20px 60px;
    min-width: 375px !important;
}

.activity_content h2 {
    font-weight: 700 !important;
    color: #247922;
    text-align: center;
}

.activity_content p {
    margin-top: 30px;
    text-align: justify;
    color: #4e4e4e;
}

.activity_images {
    width: 90%;
    min-width: 375px !important;
    margin: auto;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.activity_image {
    margin-top: 5px;
    height: 600px;
    width: 30%;
    overflow: hidden;
    position: relative;
}

.activity_image img {
    width: 95%;
    height: auto;
    margin-top: 5px;
}

.activity_images video {
    width: 30%;
    margin-top: 10px;
    margin-right: 20px;
    max-height: 300px;
}

@media screen and (max-width: 750px){
    .content.activity{
        padding: 20px 0px;
    }
    .activity_content{
        width: 95% !important;
        padding: 10px 20px;
    }
    .activity_content h2{
        text-align: center;
        font-size: 18px;
    }
    .activity_images {
        width: 100% !important;
    }

    .activity_image{
        width: 100%;
        height: auto;
    }
    .activity_image img {
        transform: none !important;
        width: 100%;
    }
    .activity_images video {
        width: 100%;
        margin-right: 0px;
        max-height: unset;
    }
}