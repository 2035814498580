.man {
  width: 100%;
  height: 88vh;
  position: relative;
  overflow: hidden;
}
.man img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.man p {
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  flex-direction: column;
  font-size: 45px;
  text-shadow: 5px 5px 10px rgb(0, 0, 0);
  color: white;
  font-weight: 700;
  letter-spacing: 2px;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.404);
}
