* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

i.caret-up {
  position: fixed;
  z-index: 1;
  right: 25px;
  cursor: pointer;
  bottom: 5%;
  border-radius: 50%;
  font-size: 30px;
  background-color: rgba(27, 25, 25, 0.493);
  display: flex;
  text-align: center;
  color: #ffffff;
  font-style: normal;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
}

#chat_with_us {
  text-decoration: none;
  position: fixed;
  z-index: 1;
  left: 45px;
  cursor: pointer;
  bottom: 30px;
  border-radius: 7px;
  color: white;
  padding: 10px 20px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  background-color: green;
  /* border: solid green 1px; */
  padding-left: 30px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.411);
  font-size: .8em;
}

#chat_with_us i {
  background-color: white;
  color: green;
  font-weight: 700;
  padding: 10px;
  border-radius: 50%;
  position: absolute;
  left: -20px;
  top: 0;
  font-size: 1.2em;
  transform: scale(1.3);
  box-shadow: 0 0 10px rgb(0, 59, 0);
}