main {
  width: 100%;
  height: auto;
  grid-auto-flow: column;
  z-index: 2;
  margin-top: 70px;
}
.hero {
  position: relative;
  width: 100%;
  height: 88vh;
}
.content {
  width: 100%;
}
.navigation { 
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: sticky;
  align-self: flex-start;
  order: 2;
  width: 100%;
  top: 70px !important;
}

.navigation.bg {
  background: url("../../Backgrounds/greek-vase.png");
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.356) !important;
  background-repeat: repeat;
  background-size: 250px;
}
.navigation ul {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.navigation ul li {
  list-style-type: none;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation ul li.active{
  border-bottom: 2px solid green;
}
.navigation ul li a {
  text-decoration: none;
  color: green;
  font-weight: 700;
}

.content.explore{
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}

.content.explore .cards {
  order: 1;
}

.content.explore .cards:first-child {
  order: 3;
}
