.content.aboutus {
    margin-top: 70px;
}

.content .owner{
    display: flex;
    min-height: 300px;
    flex-flow: row;
    width: 80%;
    margin: auto;
    align-items: flex-start;
    padding-top: 50px;
    justify-content: center;
}

.content .owner .info {
    width: 30%;
    display: flex;
    align-items: center;
    flex-flow: column;
    min-width: 250px;
    justify-content: right;
}

.content .owner .info img{
    /* border-radius: 50%; */
    /* width: 250px; */
    height: 180px;
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.644); */
    /* margin-bottom: 10px; */
}

.content .owner .info h3{
    margin-top: 10px;
    font-weight: 500;
}

.content .owner .description{
    width: 50%;
    height: 100%;
    min-width: 375px;
    padding: 50px 10px 10px;
}

.content .owner .description p{
    margin-top: 10px;
    text-align: justify;
    color: #4e4e4e;
}

.content.aboutus .services h2{
    text-align: center;
    color: #247922;
    font-weight: 600;
    margin-top: 50px;
}

.service_cards {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-flow: row wrap;
    margin: 20px 0;
}

.service_cards.serive_assistant {
    height: fit-content;
}

.service_cards .service_card {
    width: 30%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    margin-right: 20px;
    border: 1px solid rgba(0, 0, 0, 0.226);
    margin-bottom: 10px;
}

.service_cards .service_card:hover {
    transition: 0.5s;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.562);
}

.service_cards .service_card .image{
    width: 100%;
}

.service_cards .service_card .image img{
    width: 100%;
    height: auto;
    border-radius: 7px 7px 0 0;
}

.service_cards.serive_assistant .service_card .image img{
    border-radius: 7px !important;
}

.service_cards .service_card h4 {
    text-align: center;
    color: #247922;
    margin-top: 5px;
}

.service_cards .service_card p {
    text-align: justify;
    color: #4e4e4e;
    width: 90%;
    margin-top: 5px;
    margin-bottom: 10px;
}

.service_cards .service_card p a{
    text-align: center;
    display: block;
    text-decoration: none;
    color: green;
    width: 30%;
    margin: auto;
}

.service_cards .service_card p a:hover{
    text-decoration: underline;
}
.timeAddress {
    margin-top: 20px;
}

.timeAddress h2{
    text-align: center;
    color: #247922;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 15px;
}

.timeAddress p {
    color: #4e4e4e;
    width: 70%;
    height: auto;
    margin: auto;
    margin-top: 10px;
    /* box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.63); */
    border-radius: 7px;
    padding: 20px 30px;
}

.timeAddress p span {
    display: block;
    /* text-align: center; */
}

.timeAddress p span.setting{
    color: black;
    display: initial;
    font-weight: 500;
}

@media screen and (max-width: 570px){
    .content .owner{
        flex-wrap: wrap;
        padding-top: 10px;
        width: 100%;
    }
    .content .owner .description{
        width: 95%;
        padding-top: 10px;
    }
    .service_cards {
        flex-flow: column wrap;
    }
    .service_cards .service_card {
        width: 95%;
        margin-top: 10px;
        box-shadow: none;
        margin-right: unset;
        margin-bottom: unset;
    }
    .timeAddress p{
        width: 95%;
        box-shadow: none;
        border: groove;
        text-align: justify;
    }
}