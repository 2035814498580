.comments {
    background-color: rgba(0, 128, 0, 0.089);
    width: 100%;
    padding: 30px 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.247);
}

.comments h3 {
    color: rgb(8, 7, 12);
    margin-left: 15px;
}

.comments h3 span {
    font-weight: bold;
    font-size: .65em;
    color: rgba(0, 0, 0, 0.733);
}

.comments section {
    margin-top: 20px;
    font-size: .9em;
}

.comments section p {
    padding: 20px 30px;
}

.comments section p span:first-of-type{
    display: inline-block;
    margin-left: 10px;
    font-size: .8em;
    color: gray;
}

.comments section p span:last-of-type {
    display: block;
    text-align: justify;
    margin-left: 10px;
    margin-top: 5px;
    color: rgba(0, 0, 0, 0.904);
}

.comments form {
    padding: 0 20px;
    margin-top: 40px;
    justify-content: space-between;
} 

.comments form > div {
    display: flex;
    width: 100%;
    margin-top: 10px;
}

.comments form > div textarea {
    width: 78%;
    margin-right: 10px;
    border-radius: 7px;
    padding: 15px;
    outline: none;
}

.comments form > div div label {
    height: 100%;
    width: 20%;
    background-color: white;
    border: 2px solid rgba(0, 128, 0, 0.089) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.comments form > div div:first-of-type {
    width: 65%;
    display: flex;
    justify-content: center;
}

.comments form > div div:last-of-type {
    display: flex;
    width: 32%;
    flex-direction: column;
}

.comments form > div input {
    height: 40px;
}

.comments form > div input, .comments form > div textarea {
    border: 2px rgba(0, 128, 0, 0.274) solid;
    border-radius: 7px;
    padding-left: 15px;
    font-weight: 500;
    outline: none
}

.comments form > div input:first-of-type {
    margin-bottom: 10px;
}

.comments form > div input[type=submit]{
    text-transform: uppercase;
    font-weight: bold;
    background-color: rgba(0, 128, 0, 0.185);
    color: green;
    border: none;
    padding: 0px;
}

.comments form > div input[type=submit]:hover {
    transition: .5s;
    background-color: green;
    color: white;
    cursor: pointer;
}

.singleComment {
    display: flex;
    width: 100%;
}

.singleComment img {
    width: 50px;
}

@media screen and (max-width: 770px) {
    .comments form > div, .comments form > div div:first-of-type, .comments form > div div:last-of-type{
        flex-direction: column;
        width: 100%;
        height: auto !important;
    }
    .comments form > div div:first-of-type {
        height: 250px !important;
    }
    .comments form > div textarea, .comments form > div div label {
        width: 100%;
    }
    .comments form > div div label {
        margin-top: 10px;
        height: 150px;
    }
}