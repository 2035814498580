.tourHero {
    width: 100%;
    height: 65vh;
    background-image: url(Images/tour.png);
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 100px;
}

.tourHero > h1 {
    color: white;
    text-align: center;
    font-size: 2.5em;
    text-shadow: 0 0 10px black;
}

.mainTour section {
    width: 80%;
    margin: auto;
    background-color: white;
    box-shadow: 0 0 10px black;
    border-radius: 7px;
    margin-top: -150px;
    margin-bottom: 20px;
}

.mainTour section h2 {
    text-align: center;
    padding-top: 20px;
    margin-bottom: 10px;
    font-size: 1.2em;
}

.mainTour section > div{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    padding: 40px;
}

.mainTour hr {
    width: 5%;
    margin: auto;
}

.tourCard {
    width: 31%;
    min-width: 250px;
    margin-bottom: 40px;
    font-size: .9em;
}

.tourCard:hover div:first-of-type{
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.404);
}

.tourCard > div:first-of-type {
    width: 100%;
    overflow: hidden;
    height: 180px;
}

.tourCard img {
    width: 100%;
}

.tourCard > div:last-of-type {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    font-size: .9em;
}

.tourCard > div:last-of-type a{
    text-decoration: none;
    color: rgb(16, 143, 12);
    padding: 10px 20px;
    background-color: rgba(18, 197, 72, 0.205);
    font-weight: bold;
    text-transform: uppercase;
    font-size: inherit;
}

.tourCard h3 {
    margin:10px 0;
    text-align: center;
}

.tourCard p {
    text-align: justify;
}

.mainTourPage {
    font-size: 14px;
    padding: 10px;
    width: 80%;
    margin: auto;
    margin-top: 120px;
}

.mainTourPage h1 {
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 10px;
}

.mainTourPage hr {
    margin: auto;
    width: 4%;
    height: 4px;
    background-color: black;
    border: none;
    border-radius: 20px;
}

.mainTourPage button {
    border: none;
    color: green;
    background-color: rgba(0, 128, 0, 0.205);
    padding: 10px 20px;
    font-weight: bold;
    margin-bottom: 40px;
    margin-top: 30px;
    border-radius: 10px;
    animation: grow 1s linear infinite alternate-reverse;
    cursor: pointer;
}

.mainTourPage h3 {
    margin-left: -10px;
    margin-bottom: 20px;
    font-size: 1.1em;
    color: green;
    font-weight: 500;
}

.mainTourPage p {
    font-size: 1em;
    text-align: justify;
}

.mainTourPage > div {
    display: flex;
    width: 100%;
    justify-content: space-between;
} 

.mainTourPage > div .react-reveal {
    width: 48%;
}

.mainTourPage > div span.lazy-load-image-background{
    width: 100%;
    height: 100%;
}

.mainTourPage > div img {
    width: 100%;
    height: auto;
    height: 100%;
}

@keyframes grow {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.2);
    }
}
@media screen and (max-width: 770px) {
    .tourHero {
        height: 50vh;
    }
    .tourHero > h1  {
        padding-top: 20px;
        font-size: 1.5em;
    }
    .mainTour section {
        width: 100%;
        border-radius: 15px;
        box-shadow: none;
    }

    .mainTour section > div {
        padding: 10px;
    }

    .tourCard {
        width: 100%;
        font-size: 1em;
    }

    .tourCard div:first-of-type {
        height: 250px;
    }

    .tourCard > div:last-of-type  {
        font-size: 1em;
    }

    .mainTour hr {
        width: 20%;
        margin-bottom: 20px;
    }

    .mainTourPage {
        width: 98%;
    }

    .mainTourPage h3 {
        margin-left: 0px;
    }

    .mainTourPage div {
        flex-direction: column;
    }

    .mainTourPage > div .react-reveal {
        width: 100%;
    }

    .mainTourPage div img {
        width: 100%;
        margin-bottom: 20px;
    }

    
    .mainTourPage button {
        display: block;
        margin: auto;
        width: 40%;
        margin-bottom: 40px;
        margin-top: 30px;
    }
}