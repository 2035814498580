.content.rwandaInfo {
    margin: 100px auto 0;
    padding: 20px;
    width: 70%;
    > h3 {
        text-align: center;
    }

    hr {
        width: 20px;
        margin: auto;
        margin-top: 15px;
        height: 3px;
        background-color: black;
        border: none;
    }

    section {
       margin-top: 40px;
       h4 {
           margin: 20px 0;
           font-weight: 700;
           color: #247922;
       }
    //    p {
    //        margin-top: 10px;
    //        color: rgba(0, 0, 0, 0.8);
    //        text-align: justify;
    //        font-weight: 500;
    //    }
    }
}

@media screen and (max-width: 700px){
    .content.rwandaInfo {
        width: 95%;
    }
}
