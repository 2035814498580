.card {
  width: 100%;
  min-height: 350px;
  background: rgba(39, 151, 35, 0.05);
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.card .image {
  flex: 1;
  width: 50%;
  min-width: 300px;
  max-height: 350px;
  overflow: hidden !important;
}

.card .image img {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.card .desc {
  width: 50%;
  min-width: 300px;
  height: 70%;
  min-height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.card .desc .words {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.card .desc .words h2 {
  font-weight: 700;
  text-align: center;
  line-height: 70px;
  font-size: 1em;
  color: #247922;
}
.card .desc .words p {
  text-align: center;
  color: #828282;
  font-weight: 400;
  font-size: .9em;
  width: 90%;
}
.card .desc .button {
  margin-top: 30px;
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.card .desc .button button {
  /* width: 25%; */
  height: 30px;
  border: none;
  background: #247922;
  color: white;
  /* border-radius: 20px; */
  outline: none;
  transition: ease all 0.5s;
  cursor: pointer;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
}

.card .desc .button button a {
  text-decoration: none;
  color: inherit;
}
.card .desc .button button:hover {
  background: white;
  color: #247922;
}
@media (max-width: 800px) {
  .desc,
  .image {
    flex: 100%;
  }
}
