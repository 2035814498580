@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


* {
    box-sizing: border-box;
}

body::-webkit-scrollbar{
    width: 7px;
}

body::-webkit-scrollbar-thumb{
    background-color: #279723;
}

body::-webkit-scrollbar-thumb:hover{
    transition: 01s;
    background-color: #279723;
}

body::-webkit-scrollbar-track{
    background-color: white;
}

@media screen and (max-width: 450px){
    .slide div h1, .man p{
        font-size: 16px !important;
    }
    .card .desc .words p {
        font-size: 12px !important;
    }
    .card .desc .words h2 {
        font-size: 15px !important;
    }
    footer .bottom {
        position: static;
        height: 50px;
    }
    footer .right .form{
        margin-bottom: unset;
    }
    nav .links {
        width: 100% !important;
        flex-direction: row !important;
    }
}

@media screen and (max-width: 700px){
    nav {
        height: 50px;
    }
    .navigation {
        top: 50px;
        height: 40px;
        border: none !important;
    }
    main {
        margin-top: 50px !important;
    }
    nav .links {
        width: 100% !important;
        flex-direction: row !important;
    }
    nav .links ul {
        width: 50% !important;
    }
    nav .nav{
        display: none;
    }
    .mobnav {
        display: block !important;
    }
    nav .links ul{
        width: 60%;
        margin-left: 15% !important;
    }
    nav .links button {
        display: none;
    }

    .slick-arrow{
        display: none !important;
    }

    .location{
        margin-top: 50px !important;
    }

    footer .right .form{
        width: 100%;
    }

    footer .right .form form{
        width: 100%;
    }

    footer .right .icons ul {
        margin-top: 10px;
        width: 100% !important;
    }

    footer .right .icons ul li {
        width: 100% !important;
        text-align: center;
    }

    footer .right .icons ul li:nth-child(-n+3){
        display: none;
    }

    footer .right .icons ul li i {
        font-size: 1.5em;
    }
    
    footer .right .form h2{
        font-size: 17px;
    }
    footer .right .form form label{
        font-size: 14px;
    }
    footer .bottom p{
        font-size: 12px;
        font-weight: 600;
    }
    i.caret-up{
        font-size: 20px;
        height: 30px;
        width: 30px;
        bottom: 5%;
        right: 10px;
    }
}


@media screen and (max-width: 800px){

    .hero, .man {
        height: 60vh;
    }
    i.fa-caret-down {
        display: none;
    }

    .slide div h1, .man p{
        font-size: 20px;
    }
    .slick-dots{
        top: 80%;
    }
    .slick-dots li button::before{
        font-size: 5px !important;
    }
    .slide div img{
        transform: none;
    }

    .card {
        background: rgba(39, 151, 35, 0.1);
    }

    .card .desc .words h2 {
        font-size: 18px !important;
    }
    .card .desc .words p {
        font-size: 13px;
    }
    .card .desc .button button{
        border-radius: 7px;
        font-size: 12px;
    }
    .map div {
        flex-direction: column;
        width: 95%;
        margin-top: 10px;
    }

    .map div p, .map div iframe {
        width: 100% !important;
        margin-top: 10px;
    }
    footer .bottom {
        position: static;
        height: 50px;
        min-width: 320px;
    }
}

@media screen and (min-height: 1000px) and (min-width: 600px){
    .hero, .man{
        height: 700px;
    }
    .slide div img {
        width: 150%;
    }
}

@media screen and (max-width: 600px){
    .hero, .man{
        max-height: 45vh;
    }
    .slide div img {
        width: 140%;
    }
    .slide video{
        width: 100% !important;
    }
}
