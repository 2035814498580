@import "../../../../public/breakpoints.scss";

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    border: 1px solid #F5F5F5;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include media(">medium", "<=tablet"){
      // object-fit: contain;
  }
  }
  
  .swiper {
    width: 100%;
    height: 500px;
    margin: 20px auto;
    @include media(">medium", "<=tablet"){
      height: 500px;
    }
    @include media("<phone"){
      height: 600px;
    }
  }

  .append-buttons {
    text-align: center;
    margin-top: 20px;
  }
  
  .append-buttons button {
    display: inline-block;
    cursor: pointer;
    border: 1px solid #247922;
    color: #247922;
    text-decoration: none;
    padding: 4px 10px;
    border-radius: 4px;
    margin: 0 10px;
    font-size: 13px;
  }
  