.bookform {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.bookform input, .bookform select{
    color: black !important;
    font-weight: 600;
}

.bookform input[type=number], .bookform input[type=text], .bookform input[type=date], .bookform input[type=email], .bookform input[type=submit], .bookform select {
    width: 100%;
    margin-bottom: 10px;
    height: 40px;
    border-radius: 7px;
    border: 1px solid rgba(0, 128, 0, 0.212);
    padding-left: 15px;
    padding-right: 15px;
    background-color: rgba(5, 87, 5, 0.075);
}

.bookform input[type=checkbox] {
    margin: 10px;
}

.bookform label[for=preferredActivites], .bookform label[for=date]{
    margin-top: 10px;
    font-weight: 500;
}

.bookform div#preferredActivities {
    margin-bottom: 20px;
}

.bookform .date_month {
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin: 10px 0px;
    gap: 10px;
}

.bookform .date_month select {
    margin: 5px 0;
}

.bookform .date_month select:first-of-type {
    width: 30%;
}

.bookform .date_month select:last-of-type {
    width: 68%;
}
 
.bookform input[type=checkbox] + label {
    font-size: .9em;
    font-weight: 500;
}

.bookform input[type=submit] {
    background-color: green;
    color: white !important;
}