.location {
    width: 100%;
    height: 90vh;
    margin: auto;
    margin: 70px 0 30px;
}

.location iframe {
    width: 100%;
    height: 100%;
}