div.bookingsPage {
    width: 95%;
    margin: auto;
    margin-bottom: 20px;
    overflow: auto;
    height: 80vh;
    position: relative;
}

@media screen and (max-width: 770px) {
    div.bookingsPage {
        width: 100%;
        overflow: auto;
        height: initial;
    }

    table.bookings caption {
        margin: 20px;
    }
}

table.bookings {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    background-color: rgba(0, 128, 0, 0.048);
    font-size: .9em;
    white-space: nowrap;
}

table.bookings tr {
    border-bottom: 1px solid rgba(1, 112, 1, 0.082);
    font-weight: 500;
    position: relative;
}

.bookings td, .bookings th {
    padding: 20px;
    margin: 0;
}

.bookings caption {
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 1.1em;
    text-align:left;
}

.bookings tr:first-of-type {
    background-color: white;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    position: sticky;
    top: 0;
    z-index: 1;
}

.bookings tr td:nth-of-type(3), .bookings tr th:nth-of-type(3){
    position: sticky;
    left: 0;
    background-color:white;
}

.bookings tr td span {
    padding:10px;
    background-color:rgba(0, 128, 0, 0.397);
    border-radius: 20px;
    font-size:.8em;margin: 5px;
    font-weight: 600
}

.bookings button {
    background-color: rgb(214, 18, 18);
    color: white;
    border: none;
    border-radius: 7px;
    padding: 15px;
    font-weight: bold;
    cursor: pointer;
}

.passCheck {
    height: 100vh;
    width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
}

.passCheck input, .passCheck button, .passCheck span {
    margin-top: 20px;
    padding: 10px 15px;
    height: 40px;
    width: 100%;
    border-radius: 7px;
    font-weight: bold;
    font-size: .9em;
}

.passCheck input {
    border: 1px solid rgba(0, 0, 0, 0.096);
    background-color: rgba(0, 128, 0, 0.075);
}

.passCheck button {
    color: green;
    background-color: rgba(0, 128, 0, 0.164);
    border: none;
    font-weight: bold;
}

.passCheck span {
    text-align: center;
    color: rgb(173, 10, 10);

}