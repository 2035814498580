main {
  width: 100%;
  height: auto;
  grid-auto-flow: column;
  margin-top: 70px;
}
.hero {
  position: relative;
  width: 100%;
  height: 88vh;
}

i.fa-caret-down {
  position: absolute !important;
  top: 95%;
  cursor: pointer;
  left: 49.5%;
  font-size: 20px;
  color: white;
}

i.fa-caret-down:hover {
  animation: none;
}

.map h2 {
  font-size: 24px;
  color: #267B24;
  text-align: center;
  margin-top: 50px;
}

.map div {
  display: flex;
  width: 90%;
  justify-content: space-around;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 60px;
  flex-wrap: wrap;
}

.map div p {
  width: 45%;
  text-align: justify;
  font-size: 17px;
  font-weight: 400;
  color: #828282;
  min-width: 370px;
}

.map div iframe{
  width: 50%;
  min-height: 350px;
  border: none;
}

.content {
  width: 100%;
}
